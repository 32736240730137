.app-header {
  background-color: #000000;
  min-height: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #FF9900;
  font-weight: bold;
}

.app-title {
  margin-left: 100px;
  margin-right: 100px;
}

.app-intro {
  margin-left: 100px;
  margin-right: 100px;
}

.app-body {
  margin-left: 100px;
  margin-right: 100px;
  font-size: 16px
}

h3 {
  display: inline-block;
  margin-top: 20px;
  margin-bottom: 20px;
}

table {
  table-layout: fixed;
  width: 100%;
}

.my-chart {
  padding-top: 20px;
}

.MuiAutocomplete-root {
  display: inline-block; /* display the filter autocomplete block inline */
}

.react-select-inline {
  display: inline-block;
  height: 20px;
}

span {
  padding-left: 5px;
  padding-right: 5px;  /* use <span> to control space between inline components */
}

.show-filters {
  padding-top: 10px;
}

.hint-container {
  position: relative;
}

.hint {
  font-size: smaller;
  margin-left: 5px;
  color: #888;
  cursor: pointer;
}

.hint-info {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
  background-color: #f9f9f9;
  padding: 10px;
  border: 1px solid #ddd;
  color: #696969;
}

.hint:hover + div {
  display: block;
}

.show-filters {
  color: #696969;
}

.run-report {
  margin-top: 30px;
}